<template>
    <div class="home">
        <!-- <img class="logo" src="https://bhbl-prod.oss-cn-beijing.aliyuncs.com/festatic/wechat/home_top.png" alt /> -->
        <div class="logo" v-if="detail.orderStatus === ORDER_STATUS.NEW.value"></div>
        <div class="uname">{{ detail.userName }}您好!</div>
        <div class="title">您的订单{{ detail.orderId }}{{ ORDER_STATUS[detail.orderStatus] ?
            ORDER_STATUS[detail.orderStatus].label : ''
        }}</div>
        <!-- 未支付 -->
        <div class="orders" v-if="detail.orderStatus === ORDER_STATUS.NEW.value">
            <div class="title">您有一笔待支付订单</div>
            <div class="desc">
                <div class="order">
                    <span class="label">用户</span>
                    <span class="value">{{ detail.userName }}</span>
                </div>
                <div class="order">
                    <span class="label">账户</span>
                    <span class="value">{{ detail.userPhone }}</span>
                </div>
                <div class="order">
                    <span class="label">订单编号</span>
                    <span class="value">{{ detail.orderId }}</span>
                </div>
            </div>
        </div>
        <template v-else>
            <OrderComplete />
        </template>
        <!-- 新班支付须登录 -->
        <button class="btn-pay" @click="onPay" v-if="detail.orderStatus == ORDER_STATUS.NEW.value">支付</button>

        <!-- 确认姓名弹窗 -->
        <Popout v-if="isPrpout">
            <div class="msg">
                <h4>专属 {{ detail.userName }} 用户的订单</h4>如非本人请关闭并找队长更换订单
            </div>
        </Popout>
    </div>
</template>

<script>
import { ORDER_STATUS } from '@/constants/order'
import OrderModel from '@/models/order'
import Popout from '../../components/popout'
import UserModel from '@/models/user'
import OrderComplete from './OrderComplete.vue'
import { createNamespacedHelpers } from 'vuex'
const userModel = new UserModel()
const { mapGetters } = createNamespacedHelpers('user')
const order = new OrderModel()

export default {
    components: { Popout, OrderComplete },
    name: 'Home',
    data () {
        return {
            ORDER_STATUS,
            detail: {},
            isPrpout: false,
        }
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    created () {
        order.detail({ orderId: this.$route.query.orderId }).then((res) => {
            this.info = res.data
            this.detail = res.data
            if (this.detail.orderStatus === this.ORDER_STATUS.NEW.value) {
                this.isPrpout = true
            }
        })
    },
    methods: {
        //弹出框的确定按钮
        onConfirm () {
            this.isPrpout = false
        },
        //续费和新班跳转的逻辑
        onPay () {
            userModel
                .login({
                    userId: this.userInfo.id,
                    verifyCode: '',
                    type: 1,
                    password: 'hdjfdf',
                })
                .then((res) => {
                    if (res.status == 0) {
                        localStorage.clear()
                        this.$store.commit('user/AUTH', res.data.token)
                        this.$store.commit('user/USER', {
                            id: res.data.userId,
                            name: res.data.name,
                            phone: res.data.phone,
                        })
                        this.$router.push({
                            path: `/awaitpay?orderId=${this.$route.query.orderId}`,
                        })
                    }
                })

        },
    },
}
</script>

<style scoped lang="stylus" >
@import './home.styl'
</style>
