import HTTP from "@/utils/http";

class ClassModel extends HTTP {
    postAddressUpdate(data) {
        return this.post({
            url: "/classcore/schoolcalendar/user/address/update",
            data,
        });
    }
    getAddressConfirm(data) {
        return this.get({
            url: "/classcore/schoolcalendar/user/address/confirm",
            data,
        });
    }
}
export default ClassModel;
