/**
 * 订单状态
 */
export const ORDER_STATUS = {
    NEW: {
        value: "NEW",
        label: "待支付",
    },
    CANCELLED: {
        value: "CANCELLED",
        label: "已取消",
    },
    FINISHED: {
        value: "FINISHED",
        label: "已完成",
    },
};

/**
 * 订单类型
 */
export const ORDER_TYPE = {
    NEW: "NEW", // 新班？ 新生订单
    NORMAL: "NORMAL", // ? 正常进班（未推课前）？ 续费订单？
    INSERT: "INSERT", // 插班
    TRANSFER: "TRANSFER", // 调班
};

export const AppType = {
    VIP: "VIP",
    BHBL: "BHBL",
    XLSD: "XLSD",
    TRIAL: 'TRIAL', // lily讲故事优惠体验课
};
