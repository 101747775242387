<template>
    <div class="order-complete">
        <!-- 已支付去公众号看详情 -->
        <div class="orders">
            <div class="desc">
                <div class="order">
                    <div class="guide">
                        订单详细信息请进入
                        <br />微信公众号【翻转天堂2017】
                        <br />👇
                        <br />个人中心
                        <br />👇
                        <br />我的订单
                    </div>
                </div>
            </div>
        </div>
        <div div class="orders address">
            <div class="title">
                地址信息确认
            </div>
            <!-- <AddressEdit :address-info="addressInfo" :area-list="areaList" :area-columns-placeholder="['请选择', '请选择', '请选择']"
                :show-delete="false" :show-postal="false" :show-search-result="false" @save="onSave"
                save-button-text="如收货地址有变更，请联系管辖队长处理" /> -->

            <Form @submit="onSubmit" readonly>
                <CellGroup inset>
                    <Field v-model="addressInfo.recipient" name="姓名" label="姓名" placeholder="姓名"
                        :rules="[{ required: true, message: '请填写姓名' }]" />
                    <Field v-model="addressInfo.phone" name="电话" label="电话" placeholder="电话"
                        :rules="[{ required: true, message: '请填写电话' }]" />
                    <Field v-model="addressInfo.addressCodeName" name="地区" label="地区" placeholder="地区"
                        :rules="[{ required: true, message: '请填写地区' }]" />
                    <Field v-model="addressInfo.addressDetail" type="textarea" rows="2" name="详细地址" label="详细地址"
                        placeholder="详细地址" :rules="[{ required: true, message: '请填写详细地址' }]" />
                </CellGroup>
                <div style="margin: 16px;padding-bottom: 20px">
                    <Button round block type="danger">
                        如收货地址有变更，请联系管辖队长处理
                    </Button>
                </div>
            </Form>


        </div>
    </div>
</template>

<script>
import { Form, CellGroup, Button, Field } from 'vant';
import { areaList } from '@vant/area-data';
import ClassModel from '@/models/classcore';
const classcore = new ClassModel();
export default {
    components: { Form, CellGroup, Button, Field },
    name: 'OrderComplete',
    data () {
        return {
            areaList,
            addressInfo: {}
        };
    },
    created () {
        this.getAddress();
    },
    methods: {
        async getAddress () {
            const res = await classcore.getAddressConfirm();
            if (res) {
                this.addressInfo = res.data || {};
            }
        },
        // async onSave (val) {
        //     if (val) {
        //         Toast.loading('加载中...');
        //         const {
        //             name,
        //             tel,
        //             province,
        //             city,
        //             county,
        //             areaCode,
        //             addressDetail,
        //         } = val;
        //         const params = {
        //             recipient: name,
        //             phone: tel,
        //             addressCode: areaCode?.match(/(\d{2})(\d{2})(\d{2})/)?.slice(1, 4)?.join('-') ?? '',
        //             addressCodeName: `${province}-${city}-${county}`,
        //             addressDetail,
        //         }
        //         await classcore.postAddressUpdate(params)
        //         Toast.clear();
        //         this.$toast('修改地址成功');
        //     }
        // },
    },
}
</script>

<style scoped lang="stylus" >
.order-complete {
    width: 100%;
    height: 100%;
    padding: 20px 0;
}
.orders
    width 690px
    background-color #ffffff
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.05)
    border-radius 20px
    margin 0 auto
    padding 34px 32px
    .desc
      margin-top 20px
      .order
        line-height 46px
        margin-bottom 5px
        span, div
          font-family PingFangSC-Regular
          font-size 26px
          font-weight normal
          font-stretch normal
          letter-spacing 0px
          color #666666
        .label
          display inline-block
          min-width 120px
          margin-right 20px
          vertical-align top
        .value
          margin-bottom 20px
        .guide
          text-align center
.address {
    margin-top: 20px;
    padding-bottom: 0;
    .title {
        font-weight: bolder;
        text-align:center;
    }
}
:deep(.van-address-edit__buttons) {
  padding: 30px 4px 10px;
}
</style>
