<template>
  <div class="popout">
    <!-- 弹框组件 -->
    <div class="popout_box" v-if="type == 'normal'">
      <div class="popout_box_top">
        <div class="title">提示</div>
        <slot></slot>
      </div>
      <button class="popout_box_bot" @click="onConfirm">确定</button>
    </div>

    <div class="popout_box popout_box_h" v-if="type == 'custom'">
      <div class="popout_box_top">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default() {
        return "normal";
      },
    },
  },
  data() {
    return {
      isPrpout: true,
    };
  },
  methods: {
    //  点击确定
    onConfirm() {
      this.$parent.onConfirm();
    },
  },
};
</script>

<style lang='stylus'>
.popout {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.popout_box {
  border-radius: 20px;
  width: 80%;
  // height: 25%;
  background-color: white;
  padding-bottom 40px;
}
.popout_box_h{
  // height: 45%;
}
.popout_box_top {
  width: 100%;
  height: 75%;

  .title {
    text-align: center;
    height: 80px;
    line-height: 80px;
    font-size: 30px;
    font-weight: 700;
  }

  p {
    padding: 10px 30px;
    text-align: center;
    font-size: 26px;
    color: gray;
  }
}

.popout_box_bot {
  border-top: 1px solid #f8f8f8;
  border-radius: 0 0 20px 20px;
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 30px;
  background-color: #ffff;
}

.popout_box_bot p {
  width: 15%;
  height: 75%;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
